import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Col,
	Row,
	Container,
	Form,
	Label,
	Input,
	Modal,
	ModalBody,
	Button,
	Spinner,
} from 'reactstrap';

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Arrow from '../../assets/img/arrow.png';
import mobile from '../../assets/img/mobile.png';
import Arrow35 from '../../assets/img/Arrow35.png';
import { createNewLead } from '../../store/actions/streetAction';
import ThankYouForm from './Form/ThankyouForm';
import hundredImage from './../../assets/Images_Max_Crowdfund/100.png';

function Header() {
	const [step, setStep] = useState(false);
	const [Okay, setOkay] = useState(true);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [emailModal, setEmailModal] = useState(false);
	const [siteSubId, setSiteSubId] = useState('');
	const [publisherId, setPublisherId] = useState('');

	// Email Modal Toggle
	const emailToggle = () => {
		setEmailModal(!email);
	};

	const checkNameRegex = val => {
		let validName = /^([a-zA-Z ]){1,30}$/;
		// let validName = /^[a-zA-Z]+ [a-zA-Z]+$/;
		if (val != '') {
			if (validName.test(val)) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};
	const checkLastName = val => {
		let validName = /^([a-zA-Z ]){1,30}$/;
		if (val != '') {
			if (validName.test(val)) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};

	const checkEmailRegex = val => {
		let validName = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (val != '') {
			if (validName.test(val)) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};
	const checkPhoneRegex = val => {
		let validName = /^0\d{9}$/;
		if (val != '') {
			if (validName.test(val)) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};

	const dispatch = useDispatch();
	let params = useParams();
	const history = useHistory();

	useEffect(() => {
		const query = history.location.search;
		if (query) {
			const site_subid = new URLSearchParams(query).get('oa_id');
			const publisher_id = new URLSearchParams(query).get('siteid');

			setSiteSubId(site_subid);

			setPublisherId(publisher_id);
		}
	}, []);

	const handleSubmit = e => {
		e.preventDefault();

		if (
			checkNameRegex(firstName) === false &&
			checkLastName(lastName) === false &&
			checkEmailRegex(email) === false &&
			checkPhoneRegex(phoneNumber) === false
		) {
			let data = {
				firstname: firstName,
				lastname: lastName,
				email,
				phone_number: phoneNumber,
				answers: ['3338'],
				language: 'nl_NL',
				site_custom_url: 'https://maxcrowdfund.aanvraaginformatie.nl',
				site_custom_name: 'maxcrowdfund_nieuwsbrief',
				site_subid: siteSubId,
				publisher_id: publisherId,
			};
			dispatch(createNewLead(data));
			// setFirstName('');
			// setLastName('');
			// setEmail('');
			// setPhoneNumber('');
			setStep(true);
		}
	};

	const { create_response } = useSelector(state => state.street);

	const { loading } = useSelector(state => state.street);

	return (
		<>
			<div className='Header'>
				<Container>
					<Row className='py-md-0'>
						<Col
							md='7'
							className='align-self-top mt-md-5 px-0 px-md-auto'
						>
							<div className='float-right w-md-75 p-4 p-md-2 bg-blue Header_paragraph '>
								<h1 className='text-uppercase text-white text-uppercase font-family fw--800  mt-4 mt-md-0'>
									Geniet van 10% rente op jouw spaargeld.
								</h1>
								<h3 className='text-white  text-uppercase font-family fw--600 mt-4 mt-md-0'>
									Wij maken investeren toegankelijk voor
									iedereen.
								</h3>
								<ul
									className='fs--18 fw--400 justify-content-left '
									style={{
										listStyle: 'none',
										padding: '0',
									}}
								>
									<li className='text-white pl-0 ml-0   fs--18  my-2 justify-content-between'>
										<span>
											<img
												src={Arrow}
												alt='Arrow'
												className='arrow'
											/>
										</span>
										<span className='ml-3 font-family fw--400'>
											Crowdfunding binnen 1 project.
										</span>
									</li>
									<li className='text-white pl-0 ml-0  fs--18 my-2 justify-content-between'>
										<span>
											<img
												src={Arrow}
												alt='Arrow'
												className='arrow'
											/>
										</span>
										<span className='ml-3 font-family fw--400'>
											Samen met honderden investeerders
											<br />{' '}
											<span className='ml-4 pl-3'>
												leg je kleinere bedragen in
											</span>
										</span>
									</li>
									<li className='text-white pl-0 ml-0  fs--18 my-2 justify-content-between'>
										<span>
											<img
												src={Arrow}
												alt='Arrow'
												className='arrow'
											/>
										</span>
										<span className='ml-3 font-family fw--400'>
											Minimale inleg van 100,-
										</span>
									</li>
									<li className='text-white pl-0 ml-0  fs--18 my-2 justify-content-between'>
										<span>
											<img
												src={Arrow}
												alt='Arrow'
												className='arrow'
											/>
										</span>
										<span className='ml-3 font-family fw--400'>
											Met weinig geld investeren in
											vastgoed
										</span>
									</li>
								</ul>
								<div className='my-4 pb-5 pb-md-1'>
									<p className='text-green font-family fw--600 fs--22'>
										Gemiddeld rendement van 10% <br /> (bij
										spaargeld is dat toch een stuk minder).
									</p>
								</div>
							</div>

							<img
								src={mobile}
								alt='mobile'
								className='img-fluid MobileImage'
							/>
							<img
								src={hundredImage}
								alt='mobile'
								className='img-fluid float-right d-block d-md-none MobileImage_hundredImage'
							/>
						</Col>
						<Col md='5' className='align-self-bottom mt-md-5'>
							<div className='position-relative'>
								{step === true && create_response ? (
									<>
										<ThankYouForm />
									</>
								) : (
									<>
										<Form className='p-4 bg-white form-height'>
											<Container>
												<p className='text-green text-center font-family fw--600  align-self-center'>
													Al 10.000 mensen gingen je
													voor.
												</p>
												<strong className='text-blue d-block text-center line-height-1 font-family  fs--20 fw--600 my-4'>
													Ja, ik wil gratis en
													vrijblijvend tips en
													informatie ontvangen over
													laagdrempelig investeren.
												</strong>
												<span className='text-dark fw--600 p-0'>
													Voornaam
												</span>
												<Input
													required
													className='text-dark bg-secondary  pr-0 m-0 my-2 border-0 rounded-2 form-Input'
													placeholder='bv. Jan'
													invalid={checkNameRegex(
														firstName
													)}
													onChange={e =>
														setFirstName(
															e.target.value
														)
													}
												/>
												<span className='text-dark fw--600 p-0'>
													Achternaam
												</span>
												<Input
													required
													className='text-dark bg-secondary  pr-0 m-0 my-2 border-0 rounded-2 form-Input'
													placeholder='bv. Janssen'
													invalid={checkLastName(
														lastName
													)}
													onChange={e =>
														setLastName(
															e.target.value
														)
													}
												/>
												<span className='text-dark fw--600 p-0'>
													E-mail
												</span>
												<Input
													required
													className='text-dark bg-secondary pr-0 m-0 my-2 border-0 rounded-2 form-Input'
													placeholder='bv. jan@janssen.nl'
													invalid={checkEmailRegex(
														email
													)}
													onChange={e =>
														setEmail(e.target.value)
													}
												/>
												<span className='text-dark fw--600 p-0'>
													Telefoonnummer
												</span>
												<Input
													required
													className='text-dark bg-secondary pr-0 m-0 my-2 border-0 rounded-2 form-Input'
													placeholder='bv. 0612345678'
													invalid={checkPhoneRegex(
														phoneNumber
													)}
													onChange={e =>
														setPhoneNumber(
															e.target.value
														)
													}
												/>
												<div className='d-flex'>
													{firstName !== '' &&
													lastName !== '' &&
													phoneNumber !== '' &&
													email !== '' ? (
														<>
															<Button
																color='green'
																className='p-0 m-0 w-100 px-2 py-2 my-2 align-items-center font-family rounded-cornor'
																onClick={
																	handleSubmit
																}
															>
																{loading ? (
																	<Spinner
																		size='md'
																		color='light'
																	/>
																) : (
																	<>
																		<span className='text-white pl-2 font-family fw--600 align-items-center float-left'>
																			Bevestigen
																		</span>
																		<span className='text-white align-items-center float-right pr-2'>
																			{
																				<img
																					src={
																						Arrow35
																					}
																					alt='arrow35'
																					className='img-fluid'
																				/>
																			}
																		</span>
																	</>
																)}
															</Button>
														</>
													) : (
														<>
															<Button
																color='green'
																className='p-0 m-0 w-100 px-2 py-2 my-1 align-items-center  rounded-cornor'
															>
																<span className='text-white pl-2 font-family fw--600 align-items-center float-left'>
																	Bevestigen
																</span>
																<span className='text-white align-items-center float-right pr-2'>
																	{
																		<img
																			src={
																				Arrow35
																			}
																			alt='arrow35'
																			className='img-fluid'
																		/>
																	}
																</span>
															</Button>
														</>
													)}
												</div>
												<hr />
												<h5 className='my-1 text-center font-family fw--600 text-white text-blue'>
													Al meteen beginnen
												</h5>
												<h5 className='text-center text-white font-family fw--600 text-blue '>
													met investeren?
												</h5>
												<div className='d-flex'>
													<Button
														color='green'
														className='p-0 m-0 w-100 px-2 py-2 my-1 align-items-center d rounded-cornor'
														// onClick={handleSubmit}
													>
														{/* {loading ? (
															<Spinner
																size='md'
																color='dark'
															/>
														) : (
															<> */}
														<span className='text-white pl-2 align-items-center font-family fw--600 float-left'>
															Schrijf je dan hier
															in
														</span>
														<span className='text-white float-right align-items-center pr-2'>
															{
																<img
																	src={
																		Arrow35
																	}
																	alt='arrow35'
																	className='img-fluid'
																/>
															}
														</span>
														{/* </>
														)} */}
													</Button>
												</div>
												<small className='text-center fw--600  d-block '>
													{/* font-family-freez */}
													Met uw aanvraag geeft u
													toestemming aan Max
													Crowdfund om u te
													contacteren voor gratis en
													vrijblijvend advies.{' '}
												</small>
											</Container>
										</Form>
									</>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Header;
