import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import Header from '../components/Header/Header';
import Topbar from '../components/TopBar/Topbar';
import PromotionSection from '../components/PromotionSection/PromotionSection';
import AnnpakSection from '../components/AnnpakSection';
import Footer from '../components/Footer';
import AwardSection from '../components/AwardSection';

const AdminView = props => {
	return (
		<>
			<Topbar />
			<Header />
			<PromotionSection />
			<AnnpakSection />
			<AwardSection />
			<Footer />
		</>
	);
};

export default AdminView;
