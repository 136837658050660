import React from 'react';
import { useState } from 'react';
import { Button, Container, Label, Modal, ModalBody, Row } from 'reactstrap';
import FooterLogo from '../assets/Images_Max_Crowdfund/footer_logo.png';

function Footer() {
	const [modalOpen, setModalOpen] = useState(false);

	const handleModalOpen = () => {
		setModalOpen(!modalOpen);
	};

	return (
		<>
			<Modal
				isOpen={modalOpen}
				onRequestClose={handleModalOpen}
				toggle={handleModalOpen}
				centered
			>
				<Row className='justify-content-between  mr-3'>
					<Label className='float-left ml-5 mt-3 text-dark font-weight-bold'>
						Privacybeleid
					</Label>
					<Button
						className='btn btn-blue  text-center float-right mt-2'
						type='submit'
						onClick={handleModalOpen}
					>
						X
					</Button>
				</Row>
				<br />
				<ModalBody className='text-dark'>
					<div className='overflow-y'>
						<h2>Privacybeleid</h2>
						<h3>Introductie</h3>
						<p>
							Wij zijn Max Crowdfund B.V. (verwezen naar met
							ΓÇ£weΓÇ¥, ΓÇ£onsΓÇ¥ en ΓÇ£MCFΓÇ¥) en wij zijn
							toegewijd om uw privacy te respecteren en beschermen
							als u ons product gebruikt, maar niet beperkend tot,
							onze website (www.maxcrowdfund.com) onderhouden door
							ons (samen de ΓÇ£SiteΓÇ¥) als we van tijd tot tijd
							zullen publiceren en opereren, of elektronisch
							zullen communiceren met u. Termen met een
							hoofdletter zijn niet anders gedefinieerd in deze
							privacy policy, deze zullen de betekenissen hebben
							die gegeven zijn in de voorwaarden. Deze policy
							(samen met de gestelde voorwaarde die u kunt vinden
							onderaan op de Site) legt uit wat voor soorten
							persoonlijke en niet-persoonlijke informatie we
							verzamelen als u de Site gebruikt en op welke basis
							we deze gegevens van u verzamelen, en door ons
							verwerkt zullen worden.
						</p>
						<p>
							Leest u het volgende alstublieft zorgvuldig door om
							zo onze kijk en gebruiken te begrijpen met
							betrekking tot uw persoonlijke gegevens en hoe we
							hier mee om gaan. Door uw persoonlijke gegevens te
							openbaren aan ons door de site te gebruiken, of
							contact te hebben via de telefoon stemt u er mee in
							dat we deze gegevens verzamelen, opslaan en
							verwerken. We gebruiken uw persoonlijke informatie
							die we verzamelen om zo onze service aan te bieden
							aan u. We zullen uw persoonlijke informatie niet
							verhuren, verkopen of delen met een ander bedrijf
							voor haar eigen marketingdoeleinden zonder uw
							toestemming, behalve waar aangegeven in dit beleid.
							Voor de Algemene Verordening Gegevensbescherming
							(AVG), is MCF de data controller.
						</p>
						<h3>Welke persoonlijke informatie verzamelen we?</h3>
						<p>We zouden de volgende gegevens kunnen gebruiken:</p>
						<p>
							- Als u zich registreert als gebruiker op de site,
							zult u gevraagd worden om een formulier in te vullen
							waar u ons voorziet van uw naam, adres,
							geboortedatum, telefoonnummer, bankgegevens, bitcoin
							en andere wallet details en een zekere anti-witwas
							check door onder andere het delen van paspoort
							gegevens en bewijs van woonadres;
							<br />
							- Als u ons contacteert per email, telefoon of op
							een andere manier van contact die wordt geboden via
							de site, we kunnen u daarom vragen om een deel of
							alle informatie van uw computer, telefoon uiteen te
							zetten in paragraaf (a) hierboven;
							<br />
							- We kunnen gegevens verzamelen opslaan en gebruiker
							van uw computer, mobiel apparaat of andere hardware
							waarmee u toegang tot de site heeft en gebruik van
							de site maakt (inclusief geen beperkingen op
							IP-adressen, geografische locatie, browser/platform
							type en versie, Internetprovider, operating system,
							bronverwijzing, verlaten paginaΓÇÖs, duur van het
							bezoek, pagina views, site navigatie en zoektermen
							die u gebruikt);
							<br />- En om uw ervaringen te verbeteren tijdens
							het gebruik van de site, kunnen we informatie
							opslaan gerelateerd aan uw voorafgaande browser
							gewoontes om zo dingen aan te bieden die voor u
							interessant zijn.
						</p>
						<h3>Hoe gebruiken we persoonlijke informatie?</h3>
						<p>
							Wij kunnen uw persoonlijke informatie gebruiken voor
							de volgende doeleinden:
						</p>

						<p>
							{' '}
							- Om de site te managen en beheren;
							<br />
							- Om uw ervaring te verbeteren door de site te
							personaliseren;
							<br />
							- Om u in staat te stellen de site te gebruiken;
							<br />
							- Om het voor merken mogelijk te maken goederen aan
							te bieden aan u en het mogelijk te maken terugsturen
							mogelijk te maken;
							<br />
							- Om u nieuwsbrieven en andere relevante en
							doelgerichte promotie aan te bieden;
							<br />
							- Om vragen, klachten en feedback van u en onze
							serviceproviders te verwerken;
							<br />
							- Om derde partijen te voorzien van statistisch
							anonieme informatie van onze gebruikers;
							<br />
							- Om algemene transactie informatie te publiceren
							gerelateerd aan handel die gedaan wordt via de site;
							<br />
							- Om u emails te verzenden voor het deelnemen aan
							eventuele enqu├¬tes;
							<br />
							- Om de service die de site biedt te analyseren en
							verbeteren op de site.
							<br />- Wanneer zouden we persoonlijke informatie
							kunnen openbaren?{' '}
						</p>
						<br />

						<p>
							Behalve voor zover vereist door een toepasselijke
							wet of overheidsinstantie of rechterlijke instantie,
							zullen we uw persoonlijke gegevens niet bekendmaken
							aan een derde partij, behalve aan een toezichthouder
							of een dienstverlener aan wie we verplicht zijn om
							persoonlijke informatie vrij te geven om hen in
							staat te stellen hun diensten aan ons te verlenen.
							We kunnen een gekwalificeerde derde partij gebruiken
							om anti-witwas-controles en andere noodzakelijke en
							passende controles op u uit te voeren. Om ervoor te
							zorgen dat de derde partij dit doet, zullen we
							documentatie vrijgeven die persoonlijke informatie
							over u bevat, inclusief maar niet beperkt tot uw
							paspoort en andere ID-documentatie.
						</p>

						<h3>
							Nieuwsbrieven en andere
							promotie-communicatiemiddelen
						</h3>

						<p>
							Als u zich wilt abonneren op een door ons
							gepubliceerde nieuwsbrief of andere gerichte en
							relevante promotieberichten van ons of van andere
							zorgvuldig geselecteerde filialen wilt ontvangen,
							bieden wij u de optie om u aan te melden voor deze
							service. Als u van gedachten verandert en dergelijke
							berichten niet meer wilt ontvangen, klikt u op de
							link 'Abonnement opzeggen' in een e-mail van ons of
							wijzig uw voorkeur in uw persoonlijke profiel.
						</p>

						<h3>Ons gebruik van cookies en webbakens</h3>

						<p>
							Cookies zijn tekstbestanden die tijdelijk of
							permanent worden opgeslagen op de harde schijf van
							uw computer. Cookies worden gebruikt voor
							authenticatie, sessie-tracking en het bijhouden van
							specifieke informatie over het gebruik en de
							gebruikers van de Site. De gegevens die door cookies
							worden verzameld, zijn anoniem. U kunt alle cookies
							die al op de harde schijf van uw computer staan
							ΓÇïΓÇïverwijderen door naar bestanden met "Cookie"
							te zoeken en ze te verwijderen. Als u bovendien wilt
							voorkomen dat cookies worden opgeslagen op uw
							computer, kunt u uw browserinstellingen bewerken
							zodat cookies worden geblokkeerd. Helaas, als u
							cookies blokkeert, kunt u mogelijk niet alle
							functies van de site gebruiken. Ga voor meer
							informatie over cookies naar:
							www.allaboutcookies.org.
						</p>

						<p>
							Webbakens bestaan uit een kleine reeks softwarecodes
							die een grafisch beeldverzoek op een pagina of
							e-mail vertegenwoordigt. Er is al dan niet sprake
							van een zichtbare grafische afbeelding die is
							gekoppeld aan het webbaken en vaak is de afbeelding
							ontworpen om op te gaan in de achtergrond van een
							pagina of e-mail. Webbakens kunnen voor vele
							doeleinden worden gebruikt - inclusief rapportage
							van siteverkeer, unieke bezoekersaantallen,
							advertentiecontrole en rapportage en personalisatie.
							Webbakens die door de Site worden gebruikt,
							verzamelen alleen anonieme gegevens. Om de Site en
							de diensten die wij u aanbieden te verbeteren,
							kunnen we (tijdelijke en permanente) cookies,
							geautoriseerde cookies van derden, webbakens en/of
							andere technologie├½n gebruiken om niet-persoonlijk
							identificeerbare gegevens te verzamelen. Deze
							niet-persoonlijk identificeerbare gegevens helpen
							ons het surfgedrag te volgen, specifieke of op maat
							gemaakte aanbiedingen of advertenties te maken en om
							de bezoeken en het gebruik van de Site te bewaken en
							te registreren. Om te begrijpen hoe mensen de Site
							gebruiken en voor marketinganalyses en
							kwaliteitsverbeteringsdoelen, kunnen wij (en/of
							externe dienstverleners) op anonieme basis bepaalde
							informatie verzamelen (inclusief het totale aantal
							transacties), deze verwerken en gebruiken. Bekeken
							pagina's, verwijzende/eindigende pagina's,
							platformtype, datum-/tijdstempelinformatie en
							details zoals het aantal en de locatie van
							muisklikken op een bepaalde pagina, muisbewegingen,
							scrolactiviteit en de zoekwoorden die u gebruikt
							terwijl u op de Site bent en deze gebruikt.
						</p>

						<p>
							Als u zich bij ons registreert of als u de Site
							blijft gebruiken, gaat u akkoord met ons gebruik van
							cookies.
						</p>

						<h3>Waar slaan wij uw persoonlijke gegevens op?</h3>

						<p>
							De gegevens die we van u verzamelen, kunnen worden
							overgedragen naar en opgeslagen op een bestemming
							buiten de Europese Economische Ruimte ("EER"). Het
							kan ook worden verwerkt door personeel van buiten de
							EER dat voor ons werkt of voor een van onze merken.
							Door uw persoonlijke gegevens in te dienen, gaat u
							akkoord met deze overdracht, opslag of verwerking.
							We zullen alle stappen ondernemen die redelijkerwijs
							noodzakelijk zijn om ervoor te zorgen dat uw
							gegevens veilig en in overeenstemming met dit
							privacybeleid worden behandeld. Alle informatie die
							u ons verstrekt, wordt opgeslagen op onze beveiligde
							servers. Wij nemen de beveiliging van uw
							persoonlijke gegevens zeer serieus. In het bijzonder
							doen we er alles aan om ervoor te zorgen dat er
							passende veiligheidsmaatregelen zijn getroffen om uw
							persoonlijke gegevens te beschermen. Helaas is de
							overdracht van informatie via internet niet volledig
							veilig en daarom kunnen we de veiligheid van uw
							gegevens die via de site worden verzonden niet
							garanderen; elke verzending is op eigen risico.
							Zodra we uw informatie hebben ontvangen, zullen we
							strikte procedures en beveiligingsfuncties gebruiken
							om te proberen ongeautoriseerde toegang te
							voorkomen.
						</p>

						<h3>Uw rechten</h3>

						<p>
							De AVG geeft u het recht om ons te vragen uw
							persoonlijke gegevens niet voor marketingdoeleinden
							te verwerken. We zullen u gewoonlijk informeren
							(voordat we uw gegevens verzamelen) als we van plan
							zijn uw gegevens voor dergelijke doeleinden te
							gebruiken of als we van plan zijn uw informatie voor
							dergelijke doeleinden aan derden te verstrekken. U
							kunt uw recht uitoefenen om dergelijke verwerking te
							voorkomen door de meldingsvoorkeuren in te stellen
							op uw profielpagina in uw account. U kunt het recht
							ook op elk gewenst moment uitoefenen door contact
							met ons op te nemen.
						</p>

						<h3>Toegang tot informatie</h3>

						<p>
							De AVG geeft u het recht op toegang tot informatie
							die over u wordt bewaard. Uw recht op toegang kan
							worden uitgeoefend in overeenstemming met de AVG.
							Voor elk toegangsverzoek kan een vergoeding worden
							gevraagd om onze kosten te dekken door u informatie
							te verstrekken over de informatie die wij over u
							bewaren.
						</p>

						<h3>Wijzigingen in ons privacybeleid</h3>

						<p>
							We behouden ons het recht voor om dit privacybeleid
							te wijzigen. Alle wijzigingen die we in de toekomst
							aanbrengen in ons privacybeleid zullen op deze
							pagina worden geplaatst en, indien van toepassing,
							per e-mail aan u worden meegedeeld.
						</p>

						<h3>Contact</h3>

						<p>
							Vragen, opmerkingen en verzoeken met betrekking tot
							dit privacybeleid zijn welkom en dienen te worden
							gericht aan info@maxpropertygroup.com of per post
							aan: Max Crowdfund B.V. Brielselaan 85, 3081 AB
							Rotterdam, Nederland.
						</p>
					</div>
				</ModalBody>
				<div className='align-self-end mr-2'>
					<Button
						className='btn btn-blue text-center mb-3'
						type='button'
						onClick={handleModalOpen}
					>
						Ok
					</Button>
				</div>
			</Modal>
			<Container fluid className='bg-blue'>
				<Row className='justify-content-center'>
					<div className='py-4 justify-content-center text-center'>
						<div className='text-center'>
							<img
								src={FooterLogo}
								alt='FooterLogo'
								className='img-fluid'
							/>
						</div>
						<br />
						<small className='text-white text-left align-self-center'>
							<span
								className='pointer-cursor text-white'
								onClick={() => setModalOpen(true)}
							>
								Privacybeleid
							</span>{' '}
							|{' '}
							<a
								href='https://cdn1.hubspot.net/hubfs/2718962/Documents%20Max%20Crowdfund/Terms__Conditions_Max_Crowdfund_B.V._(NL)_20210426.pdf'
								className='pointer-cursor text-white'
								target='_blank'
							>
								Algemene voorwaarden
							</a>
						</small>
					</div>
				</Row>
			</Container>
		</>
	);
}

export default Footer;
