import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
// import RightArrow from '../assets/img/rightArrow.png';
import NewLoan from '../assets/img/NewLoan.png';
import HouseAnnpak from '../assets/Images_Max_Crowdfund/HouseAnnpak.png';
import Award from '../assets/Images_Max_Crowdfund/Award.png';
import logoAward1 from '../assets/Images_Max_Crowdfund/logoAward1.png';
import logoAward2 from '../assets/Images_Max_Crowdfund/logoAward2.png';
import logoAward3 from '../assets/Images_Max_Crowdfund/logoAward3.png';
import logoAward4 from '../assets/img/rtlz.png';
// import logoAward4 from '../assets/Images_Max_Crowdfund/logoAward4.png';
import Arrow35 from '../assets/img/Arrow35.png';
function AwardSection() {
	return (
		<>
			<Container className='bg-white' fluid>
				<Container>
					<div className='w-100 justify-content-center text-center p-2 w-100 my-4 AwardSection'>
						<Button
							color='green'
							className='mx-auto my-5 px-2 fs--20 justify-content-between align-self-center text-white font-weight rounded-cornor Beginnen_button'
						>
							<span className='align-self-center align-items-center justify-content-center  px-4 font-family fw--600'>
								Beginnen met laagdrempelig investeren
							</span>
							<span className='ml-md-3 px-1 float-md-right align-self-center align-items-center justify-content-center '>
								{
									<img
										src={Arrow35}
										alt='arrow35'
										className='img-fluid'
									/>
								}
							</span>
						</Button>
					</div>
					<Row className='p-3 my-5 '>
						<Col
							md='6'
							className='border-right border-right-1  border-right-green AwardSection_logos_Column'
						>
							<div className='py-4 '>
								<h4 className='text-uppercase text-center text-md-left font-family fs--28 fw--800 text-blue '>
									Ook bekend van
								</h4>
								<div className='d-flex align-self-center  pl-0 pt-3 flex-wrap'>
									<div className='align-self-center'>
										<img
											src={logoAward1}
											alt='logoAward1'
											className='rounded pl-0 ml-0 pr-2 my-1 img-fluid height-fit-content'
										/>
									</div>
									<div className='align-self-center'>
										<img
											src={logoAward2}
											alt='logoAward2'
											className='rounded px-2 my-1 img-fluid height-fit-content'
										/>
									</div>
									<div className='align-self-center'>
										<img
											src={logoAward3}
											alt='logoAward3'
											className='rounded px-2 my-1 img-fluid height-fit-content'
										/>
									</div>
									<div className='align-self-center'>
										<img
											src={logoAward4}
											alt='logoAward4'
											className='rounded px-2 my-1 img-fluid height-fit-content'
										/>
									</div>
								</div>
							</div>
						</Col>
						<Col
							md='6'
							className='mt-4 mt-md-0  border-left border-left-1  border-left-green AwardSection_text_Column'
						>
							<div className='d-flex flex-wrap py-4 pl-3'>
								<div className='sideBar-text align-self-top '>
									<h4 className='text-uppercase font-family fs--28 fw--800 text-blue'>
										cashcow award 2022
									</h4>
									<p className='fs--22 fw--400 font-family text-blue  pt-2'>
										Max Crowdfund verkozen tot beste
										crowdfunding platform 2022 op de
										BeleggersFair in Amsterdam.
									</p>
								</div>
								<div className='d-flex flex-wrap  sidebar-logo'>
									<img
										src={Award}
										alt='Award'
										className='w-100 '
									/>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
}

export default AwardSection;
