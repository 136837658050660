import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Contact from '../../assets/img/Contact.png';
import Coin from '../../assets/img/Coin.png';
import Home from '../../assets/img/home.png';
import NoteBook from '../../assets/img/NoteBook.png';
import mobile from '../../assets/img/mobile.png';
import downArrow from '../../assets/img/down-arrow.png';
import { HmacSHA224 } from 'crypto-js';
import Oneimg from '../../assets/img/1.png';
// import Twoimg from '../../assets/img/2.png';
import Twoimg from '../../assets/img/number-2.png';
// import Threeimg from '../../assets/img/3number.png';
import Threeimg from '../../assets/img/3.png';
import FourImg from '../../assets/img/4.png';

function PromotionSection() {
	return (
		<Container fluid>
			{/* <img src={mobile} alt='mobile' className='promotion-mobile' /> */}
			<Container>
				<Row className='mt-5 promotionSection'>
					<Col
						xs='12'
						md='12'
						className='p-2 p-md-auto align-self-center'
					>
						<div className='text-left mt-5'>
							<h2 className='font-family text-blue fw--800 mt-3 mt-md-0 promotionSection_promotion-heading'>
								HOE WERKT HET?
							</h2>
							<Row className='d-flex text-center align-self-center'>
								<Col
									sm='12'
									md='3'
									className='align-self-center'
								>
									<div>
										<div className='float-left rounded-circle promotionSection_logo-text'>
											{/* <span classNa me='fs--22 font-weight-bold '>
												1 bg-dark  text-white  s
											</span> */}
											<img
												src={Oneimg}
												alt='1'
												className='img-fluid'
											/>
										</div>
										<img
											src={Contact}
											alt='Contact'
											className='promotionSection_promotion_image'
										/>
										<p className='fw--400 font-family fs--18 mt-4 mt-md-2 text-blue'>
											Maak een <br />
											account aan
										</p>
									</div>
								</Col>

								<Col
									sm='12'
									md='3'
									className='align-self-center p-md-2'
								>
									<div>
										<div className=' float-left rounded-circle promotionSection_logo-text'>
											{/* <span className='fs--22 font-weight-bold'>
												2 bg-dark  text-white  
											</span> */}
											<img
												src={Twoimg}
												alt='2'
												className='img-fluid'
											/>
										</div>
										<img
											src={NoteBook}
											alt='NoteBook'
											className='promotionSection_promotion_image'
										/>
										<p className='font-family fw--400 fs--18 mt-4 mt-md-2 text-blue'>
											Bekijk alle beschikbare <br />
											investeringskansen
										</p>
									</div>
								</Col>
								<Col
									sm='12'
									md='3'
									className='align-self-center'
								>
									<div>
										<div className='float-left rounded-circle promotionSection_logo-text'>
											{/* bg-dark  text-white */}
											{/* <span className='fs--22 font-weight-bold'>
												3
											</span> */}
											<img
												src={Threeimg}
												alt='2'
												className='img-fluid'
											/>
										</div>
										<img
											src={Home}
											alt='Home'
											className='promotionSection_promotion_image'
										/>
										<p className='fw--400 font-family fs--18 mt-4 mt-md-2 text-blue '>
											Kies en beleg
											<br />
											vanaf 100,- inleg
										</p>
									</div>
								</Col>
								<Col
									sm='12'
									md='3'
									className='align-self-center'
								>
									<div>
										<div className='float-left rounded-circle  promotionSection_logo-text '>
											{/* <span className='fs--22 font-weight-bold promotion-number '>
												4
											</span> */}
											<img
												src={FourImg}
												alt='4'
												className='img-fluid'
											/>
										</div>
										<img
											src={Coin}
											alt='Coin'
											className='promotionSection_promotion_image'
										/>
										<p className='fw--400 font-family fs--18 mt-4 mt-md-2 text-blue '>
											Ontvang rente
											<br />
											over je investering
										</p>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
				<div className='justify-content-center align-self-center w-100'>
					<h2 className='my-4  text-blue text-uppercase text-center font-family fw--800 mx-auto'>
						De voordelen van vastgoed crowdfunding
					</h2>
				</div>

				<div className='down-promotion-icon mx-auto text-center'>
					{/* <img
						src={downArrow}
						alt='downArrow'
						className='mx-auto down-promotion-arrow'
					/> */}

					<i className='fas fa-arrow-down fs--40 text-blue '></i>
				</div>
			</Container>
		</Container>
	);
}

export default PromotionSection;
