import { RepositoryFactory } from '../../repository/RepositoryFactory';
var LeadRepo = RepositoryFactory.get('newLead');

export const createNewLead =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(createLoader(true));
		try {
			let { data } = await LeadRepo.createNewLead(payload);
			if (data) {
				dispatch({
					type: 'CREATE_RESPONSE',
					payload: data,
				});
				onSuccess();
				await dispatch(createLoader(false));
			} else {
				await dispatch(createLoader(false));
			}
		} catch (e) {
			alert(e.response.data.error);

			await dispatch(createLoader(false));
		}
	};
export const createLoader = val => async dispatch => {
	dispatch({
		type: 'CREATE_LOADER',
		payload: val,
	});
};
