import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import RightArrow from '../assets/img/rightArrow.png';
import NewLoan from '../assets/img/NewLoan.png';
import HouseAnnpak from '../assets/Images_Max_Crowdfund/HouseAnnpak.png';

function AnnpakSection() {
	return (
		<>
			<Container className='bg-annpak-color' fluid>
				<Container className='Annpak '>
					<Row className='mt-md-5 justify-content-center'>
						<Col md='4' className='align-self-center mt-5 p-md-0'>
							<div className='d-flex flex-column justify-content-between'>
								<h5 className='fs--18  text-blue text-left text-md-right font-family fw--400 '>
									Laagdrempelig investeren in <br />
									vastgoed d.m.v. crowdfunding
								</h5>
								<h5 className='fs--18 text-md-right text-left text-blue mt-4 font-family fw--400 '>
									Het beste alternatief op sparen;
									<br />
									<span>
										beleggen heeft consistentie en zekerheid
									</span>
								</h5>
							</div>
						</Col>
						<Col
							md='3'
							className='mt-5 text-center align-self-center justify-content-center '
						>
							<img
								src={NewLoan}
								alt='NewLoan'
								className='img-fluid '
							/>
						</Col>
						<Col md='4' className='align-self-center mt-5 p-md-0'>
							<h5 className='fs--18  text-left  text-blue font-family fw--400'>
								Minimaal investeringsbedrag
								<br />
								van 100,-
							</h5>

							<h5 className='fs--18 mt-4 text-blue text-left  font-family fw--400'>
								Gemiddeld rendement van 10%
								<br />
								(bij spaargeld is dat toch een stuk minder).
							</h5>
						</Col>
					</Row>

					<Row className='mt-3'>
						<Col className='align-self-center'>
							<h4 className='float-left float-md-right  text-green  fs--39 fw--800 text-uppercase annpak_Al_text'>
								Al 10.000 gebruikers
								<br />
								<span className='float-left float-lg-right'>
									OP ons platform
								</span>
							</h4>
						</Col>
						<Col className='align-self-center'>
							<img
								src={HouseAnnpak}
								alt='HouseAnnpak'
								className='img-fluid houseAnnpak'
							/>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
}

export default AnnpakSection;
