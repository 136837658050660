import React from 'react';
import { Container, Row } from 'reactstrap';
import Logo from '../../assets/img/Laag1.png';

function Topbar() {
	return (
		<>
			<div className='bg-white   Navbar'>
				<Container>
					<Row className='justify-content-between align-self-center px-2 py-2'>
						<img
							src={Logo}
							alt='logo'
							className='ml-md-4 my-auto mx-auto mr-md-auto Navbar_logo'
							//
						/>
						<h3 className='fs--22 fw--600 font-family text-blue my-auto  ml-md-auto mr-md-0 Navbar_text'>
							Hét crowdfunding platform voor vastgoed
						</h3>
						{/* my-auto mx-auto */}
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Topbar;
