// Root Reducer

import { combineReducers } from 'redux';

import streetReducer from './streetReducer';

export let rootReducer = combineReducers({
	
	street: streetReducer,
});

export default rootReducer;
